<template>
  <div>
    <v-row>
      <v-col v-if="item" cols="12" sm="6">
        <vx-card-list title="Basic Info" :item="item" hide-edit>
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Message Number </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item.messageNumber }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('account', item.to._id)">
            <v-list-item-title> Host profile </v-list-item-title>
            <v-list-item-subtitle> {{ item.to?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item :to="$toView('account', item.from._id)">
            <v-list-item-title> Artist profile </v-list-item-title>
            <v-list-item-subtitle> {{ item.from?.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Host URL </v-list-item-title>
            <v-list-item-subtitle
              class="cursor-pointer info--text"
              @click="$copy(`${artistURL}/${item.to.slug}/a/inbox/message/${item.from.slug}`)"
            >
              {{ `${artistURL}/${item.to.slug}/a/inbox/message/${item.from.slug}` }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Artist URL </v-list-item-title>
            <v-list-item-subtitle
              class="cursor-pointer info--text"
              @click="$copy(`${artistURL}/${item.from.slug}/a/inbox/message/${item.to.slug}`)"
            >
              {{ `${artistURL}/${item.from.slug}/a/inbox/message/${item.to.slug}` }}
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12">
        <message-base
          :filter="{
            messageId: $route.params.id,
          }"
          @getMessage="getMessage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MessageBase from './base';

export default {
  name: 'MessageView',
  components: {
    MessageBase,
  },
  data() {
    return {
      item: null,
    };
  },
  methods: {
    getMessage(item) {
      this.item = item;
    },
  },
};
</script>
