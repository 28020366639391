var render = function () {
  var _vm$item$to, _vm$item$from;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_vm.item ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic Info",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Message Number ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.messageNumber) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('account', _vm.item.to._id)
    }
  }, [_c('v-list-item-title', [_vm._v(" Host profile ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$to = _vm.item.to) === null || _vm$item$to === void 0 ? void 0 : _vm$item$to.name) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toView('account', _vm.item.from._id)
    }
  }, [_c('v-list-item-title', [_vm._v(" Artist profile ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$item$from = _vm.item.from) === null || _vm$item$from === void 0 ? void 0 : _vm$item$from.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Host URL ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy("".concat(_vm.artistURL, "/").concat(_vm.item.to.slug, "/a/inbox/message/").concat(_vm.item.from.slug));
      }
    }
  }, [_vm._v(" " + _vm._s("".concat(_vm.artistURL, "/").concat(_vm.item.to.slug, "/a/inbox/message/").concat(_vm.item.from.slug)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Artist URL ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy("".concat(_vm.artistURL, "/").concat(_vm.item.from.slug, "/a/inbox/message/").concat(_vm.item.to.slug));
      }
    }
  }, [_vm._v(" " + _vm._s("".concat(_vm.artistURL, "/").concat(_vm.item.from.slug, "/a/inbox/message/").concat(_vm.item.to.slug)) + " ")])], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('message-base', {
    attrs: {
      "filter": {
        messageId: _vm.$route.params.id
      }
    },
    on: {
      "getMessage": _vm.getMessage
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }